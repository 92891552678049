<template>
    <div>
        <h2>Watchlist Management</h2>

        <div class="mb-3">
            <div class="btn-group">
                <button v-for="(label, type) in wlTypesPlural" :key="type" type="button" class="btn btn-primary" :class="{ active: typeFilter === type }" @click.prevent="typeFilter = type">{{ label }}</button>
            </div>
            <button type="button" class="btn ml-2" :class="{ 'btn-secondary': !canDelete, 'btn-warning': canDelete }" @click.prevent="canDelete = !canDelete">{{ canDelete ? 'Lock deletion' : 'Unlock deletion' }}</button>
        </div>

        <form class="row">
            <div class="col-4 form-group">
                <label for="wl-lang">Language</label>
                <LanguageSelector v-model="wlNewLang" :from-project="wlNewProj" id="wl-lang" class="form-control"></LanguageSelector>
            </div>
            <div class="col-4 form-group">
                <label for="wl-proj">Project</label>
                <ProjectSelector v-model="wlNewProj" :project-lang="wlNewLang" id="wl-proj" class="form-control"></ProjectSelector>
            </div>
            <div class="col-2 form-group">
                <template v-if="typeFilter !== 'ignore'">
                    <label for="wl-notify">Notify</label>
                    <ToggleButton class="form-control" id="wl-notify" v-model="wlNewNotify"></ToggleButton>
                </template>
            </div>
            <div class="col-2 form-group">
                <template v-if="typeFilter === 'article'">
                    <label for="wl-noignore">Always</label>
                    <ToggleButton class="form-control" id="wl-noignore" v-model="wlNewNoignore"></ToggleButton>
                </template>
            </div>
            <div class="col-3 form-group">
                <label for="wl-value">{{ wlTypes[typeFilter] }}</label>
                <input id="wl-value" class="form-control" v-model="wlNewValue">
            </div>
            <div class="col-4 form-group">
                <label for="wl-comments">Comments</label>
                <input id="wl-comments" class="form-control" v-model="wlNewComments">
            </div>
            <div class="col-2 form-group">
                <label for="wl-category">Category</label>
                <input id="wl-category" class="form-control" v-model="wlNewCategory">
            </div>
            <div class="col-2 form-group">
                <label for="wl-expiresat">Expiration</label>
                <select id="wl-expiresat" class="form-control" v-model="wlNewExpiresat">
                    <option v-for="(label, value) in wlExpireOptions" :key="value" :value="value">{{ label }}</option>
                </select>
            </div>
            <div class="col-1 form-group">
                <label>&nbsp;</label>
                <button class="form-control btn btn-primary" @click.prevent="addNewItem()" :disabled="!addValid">Add</button>
            </div>
            <div v-if="addNewItemError !== undefined" class="col-12">
                <div class="alert alert-danger">
                    {{ addNewItemError }}
                    <button type="button" class="close" @click.prevent="addNewItemError = undefined">&times;</button>
                </div>
            </div>
        </form>

        <table class="table clearfix">
            <tr>
                <th>Wiki</th>
                <th>{{ wlTypes[typeFilter] }}</th>
                <th v-if="showCategory">Category</th>
                <th v-if="showNotify">Notify</th>
                <th v-if="showNoignore">Always</th>
                <th>Expiration</th>
                <th>&nbsp;</th>
            </tr>
            <tr v-if="loading">
                <td :colspan="tableSize">Loading...</td>
            </tr>
            <tr v-else-if="error">
                <td :colspan="tableSize">{{ error }}</td>
            </tr>
            <tr v-else-if="filteredItems.length === 0">
                <td :colspan="tableSize"><div class="alert alert-secondary">No entries to display</div></td>
            </tr>
            <tr v-else v-for="item in filteredItems" :key="item.id">
                <td>{{ item.item.wiki }}</td>
                <td>{{ item.label }}<template v-if="item.item.comments && item.item.comments.length"><br><span class="text-muted" style="font-size: 75%">{{ item.item.comments }}</span></template></td>
                <td v-if="showCategory"><span class="badge badge-primary" v-if="item.item.category && item.item.category.length">{{ item.item.category }}</span></td>
                <td v-if="showNotify">
                    <button type="button" :disabled="item.busy" class="btn btn-sm" :class="{ 'btn-success': item.item.notify, 'btn-secondary': !item.item.notify }" @click.prevent="toggleAttr(item, 'notify')">{{ item.item.notify ? 'Yes' : 'No' }}</button>
                </td>
                <td v-if="showNoignore"><button type="button" v-if="item.type === 'article'" :disabled="item.busy" class="btn btn-sm" :class="{ 'btn-success': item.item.noignore, 'btn-secondary': !item.item.noignore }" @click.prevent="toggleAttr(item, 'noignore')">{{ item.item.noignore ? 'Yes' : 'No' }}</button></td>
                <td :class="{ 'text-warning': item.item.expires_at && now.isSameOrAfter(moment.utc(item.item.expires_at)) }">{{ item.item.expires_at ? now.to(moment.utc(item.item.expires_at)) : '' }}</td>
                <td class="text-right">
                    <button type="button" :disabled="item.busy" v-show="!canDelete" class="btn btn-sm btn-primary" @click.prevent="editItem(item)">Edit</button>
                    <button type="button" :disabled="item.busy" v-show="canDelete" class="btn btn-sm btn-danger" @click.prevent="deleteItem(item)">Delete</button>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import reject from 'lodash/reject';
import size from 'lodash/size';
import trim from 'lodash/trim';
import filter from 'lodash/filter';

import moment from 'moment';
import ip6addr from 'ip6addr';

import LanguageSelector from '../components/LanguageSelector.vue';
import ProjectSelector from '../components/ProjectSelector.vue';
import ToggleButton from '../components/ToggleButton.vue';

import api from '../api';

function itemMapper(type, idAttr) {
    return i => ({
        type,

        id: `${type}|${i.wiki}|${i[idAttr]}`,
        label: i[idAttr],

        item: i,
        busy: false,
    });
}

const articleMapper = itemMapper('article', 'title');
const editorMapper = itemMapper('editor', 'editor');
const networkMapper = itemMapper('network', 'network');
const ignoreMapper = itemMapper('ignore', 'editor');

function convertExpiration(exp) {
    if (exp && exp.length) {
        return moment.utc().add(...(exp.split('|'))).toISOString();
    }
}

const endpoints = {
    article: api.watchlist.article,
    editor: api.watchlist.editor,
    network: api.watchlist.network,
    ignore: api.ignore,
};

function networkValid(value) {
    try {
        ip6addr.parse(value);
        return true;
    } catch (err) { /* */ }

    try {
        ip6addr.createCIDR(value);
        return true;
    } catch (err) { /* */ }

    return false;
}

function editorValid(value) {
    //TODO actually parse (needs async)
    return !!(
        size(trim(value)) &&
        !/:/.test(value) &&
        !networkValid(value)
    );
}

const valueValid = {
    //TODO actually parse (needs async)
    article(value) {
        return !!size(trim(value));
    },

    editor: editorValid,
    network: networkValid,
    ignore: editorValid,
};

export default {
    components: {
        LanguageSelector,
        ProjectSelector,
        ToggleButton,
    },

    data: () => ({
        moment,

        items: undefined,

        loading: false,
        error: undefined,

        now: undefined,
        nowInterval: undefined,

        canDelete: false,

        typeFilter: 'article',

        wlTypes: {
            article: 'Article',
            editor: 'Editor',
            network: 'Network',
            ignore: 'Ignored editor',
        },

        wlTypesPlural: {
            article: 'Articles',
            editor: 'Editors',
            network: 'Networks',
            ignore: 'Ignored editors',
        },

        wlExpireOptions: {
            '': 'Never',
            '1|h': '1 hour',
            '3|h': '3 hours',
            '12|h': '12 hours',
            '1|d': '1 day',
            '2|d': '2 days',
            '7|d': '7 days',
            '14|d': '14 days',
            '1|M': '1 month',
            '3|M': '3 months',
            '6|M': '6 months',
            '1|y': '1 year',
        },

        wlNewType: 'article',
        wlNewLang: 'en',
        wlNewProj: '',
        wlNewNotify: false,
        wlNewNoignore: false,
        wlNewValue: '',
        wlNewComments: '',
        wlNewCategory: '',
        wlNewExpiresat: '',

        addNewItemError: undefined,
    }),

    computed: {
        addValid() {
            const { typeFilter, wlNewProj, wlNewValue } = this;

            return !!(
                size(wlNewProj) &&

                size(typeFilter) &&
                valueValid[typeFilter] &&
                valueValid[typeFilter](wlNewValue)
            );
        },

        filteredItems() {
            return filter(this.items, { type: this.typeFilter });
        },

        showCategory() {
            return this.typeFilter !== 'ignore';
        },

        showNotify() {
            return this.typeFilter !== 'ignore';
        },

        showNoignore() {
            return this.typeFilter === 'article';
        },

        tableSize() {
            // Booleans coerce to number as 0 or 1, which is useful here.
            return 4 + this.showCategory + this.showNotify + this.showNoignore;
        },
    },

    mounted() {
        const updateNow = () => { this.now = moment(); };

        this.nowInterval = setInterval(updateNow, 500);
        updateNow();

        this.refresh();
    },

    unmounted() {
        clearInterval(this.nowInterval);
    },

    methods: {
        async refresh() {
            this.items = undefined;

            this.loading = true;
            this.error = undefined;

            try {
                const [ articles, editors, networks, ignored ] = await Promise.all([
                    api.watchlist.article.getAll(),
                    api.watchlist.editor.getAll(),
                    api.watchlist.network.getAll(),
                    api.ignore.getAll(),
                ]);

                this.items = orderBy(
                    [
                        ...map(articles, articleMapper),
                        ...map(editors, editorMapper),
                        ...map(networks, networkMapper),
                        ...map(ignored, ignoreMapper),
                    ],
                    'id'
                );
            } catch (err) {
                this.error = err.message;
            } finally {
                this.loading = false;
            }
        },

        async withItem(outerItem, fn) {
            outerItem.busy = true;

            try {
                await fn();
            } catch (err) {
                this.error = err.message;
            } finally {
                outerItem.busy = false;
            }
        },

        async mutateItem(outerItem, fn) {
            await this.withItem(outerItem, async () => {
                const item = outerItem.item;

                const copy = { ...item };

                const mutated = (await fn(copy)) || copy;

                const result = await api.putObject(mutated);

                Object.assign(item, result);
            });
        },

        async toggleAttr(outerItem, attr) {
            return this.mutateItem(outerItem, i => { i[attr] = !i[attr]; });
        },

        async deleteItem(outerItem) {
            await this.withItem(outerItem, async () => {
                await api.deleteObject(outerItem.item);

                this.items = reject(this.items, { id: outerItem.id });
            });
        },

        editItem(item) {
            Object.assign(this, {
                wlNewLang: '',
                wlNewProj: item.item.wiki,
                wlNewNotify: !!item.item.notify,
                wlNewNoignore: !!item.item.noignore,
                wlNewValue: item.label,
                wlNewComments: item.item.comments || '',
                wlNewCategory: item.item.category || '',
                wlNewExpiresat: '',
            });
        },

        resetWlNew() {
            Object.assign(this, {
                wlNewValue: '',
                wlNewComments: '',
                wlNewCategory: '',
            });
        },

        async addNewItem() {
            this.addNewItemError = undefined;

            try {
                await endpoints[this.typeFilter].put(this.wlNewProj, this.wlNewValue, {
                    comments: this.wlNewComments,
                    category: this.wlNewCategory,
                    notify: this.typeFilter !== 'ignore' ? this.wlNewNotify : undefined,
                    noignore: this.typeFilter === 'article' ? this.wlNewNoignore : undefined,
                    expires_at: convertExpiration(this.wlNewExpiresat),
                });

                this.refresh();
                this.resetWlNew();
            } catch (err) {
                this.addNewItemError = `Add entry failed: ${err.response?.data?.error || err.message}`;

                global.console.log(err);
            }
        },
    },
};
</script>
