<template>
    <form>
        <div v-if="reason" class="alert alert-primary">{{ reason }}</div>
        <div class="form-group">
            <label for="username">Username</label>
            <input id="username" v-model="username" class="form-control" :class="{'is-invalid':error}" placeholder="Username">
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input id="password" v-model="password" class="form-control" :class="{'is-invalid':error}" type="password">
            <div class="invalid-feedback">Invalid credentials</div>
        </div>
        <button :disabled="!valid || loading" class="col-12 btn btn-primary" @click.prevent="login">
            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
            Log in
        </button>
    </form>
</template>

<script>
import api from '../api';

function notEmpty(str) {
    return typeof str === 'string' && !!str.length;
}

export default {
    data: () => ({
        username: '',
        password: '',

        loading: false,
        error: false,

        reason: undefined,
    }),

    computed: {
        valid() {
            const { username, password } = this;

            return notEmpty(username) && notEmpty(password);
        },
    },

    methods: {
        async login() {
            if (!this.valid) { return; }

            this.error = false;
            this.loading = true;

            try {
                await api.authenticate(this.username, this.password);

                this.$router.push('/');
            } catch (err) {
                global.console.log(err);
                this.error = true;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
