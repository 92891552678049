<template>
    <select v-model="value">
        <option value="specials">Special projects</option>
        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{ lang.name }} ({{ lang.code }})</option>
    </select>
</template>

<script>
import orderBy from 'lodash/orderBy';
import toLower from 'lodash/toLower';
import deburr from 'lodash/deburr';
import size from 'lodash/size';
import find from 'lodash/find';

import { getSiteMatrix } from '../siteinfo';

export default {
    props: ['modelValue', 'fromProject'],
    emits: ['update:modelValue'],

    data: () => ({
        languages: [],
    }),

    watch: {
        modelValue() {
            this.refresh();
        },

        fromProject() {
            this.refresh();
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async refresh() {
            const matrix = await getSiteMatrix();

            const langs = [];

            for (let i = 0; matrix[i]; ++i) {
                langs.push(matrix[i]);
            }

            this.languages = orderBy(langs, i => toLower(deburr(i.name)));

            if (size(this.modelValue) === 0 && size(this.fromProject) !== 0) {
                // Look for the language that matches the initial project.
                const lang = find(this.languages, l => l.site && find(l.site, { dbname: this.fromProject })) || (
                    find(matrix.specials, { dbname: this.fromProject }) && { code: 'specials' }
                );

                if (lang) {
                    this.$emit('update:modelValue', lang.code);
                }
            }
        },
    },

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            },
        },
    },
};
</script>
