<template>
    <div>
        <h2>Settings</h2>

        <div v-if="loading">Loading...</div>
        <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
        <form v-else-if="identity" class="row">
            <div class="col-6 form-group">
                <label for="password1">Change password</label>
                <input :disabled="saving" type="password" class="form-control" v-model="password1">
            </div>
            <div class="col-6 form-group">
                <template v-if="password1.length">
                    <label for="password2">Confirm new password</label>
                    <input :disabled="saving" type="password" class="form-control" v-model="password2">
                </template>
            </div>
            <div class="col-4 form-group">
                <label for="notifymethod">External notification method</label>
                <select :disabled="saving" class="form-control" v-model="identity.notify_method">
                    <option v-for="method in methods" :key="method.value" :value="method.value">{{ method.label }}</option>
                </select>
            </div>

            <div v-if="identity.notify_method === 'discord'" class="col-8 form-group">
                <label for="discordendpoint">Webhook URL</label>
                <input :disabled="saving" class="form-control" placeholder="https://discord.com/api/webhooks/..." v-model="identity.notify_config.endpoint">
            </div>

            <div class="col-12 text-right">
                <button :disabled="saving || !isValid" class="btn btn-primary" @click.prevent="save()">Save</button>
            </div>

            <div class="col-12 mt-3" v-if="saveError">
                <div class="alert alert-danger">
                    {{ saveError }}
                    <button type="button" class="close" @click.prevent="saveError = undefined">&times;</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import api from '../api';

const methods = [
    { value: undefined, label: 'None' },
    { value: 'discord', label: 'Discord webhook' },
];

const validators = {
    discord: config => /^https:\/\/discord\.com\/api\/webhooks\/[0-9]+\/[^/]+$/.test(config.endpoint),
};

export default {
    data: () => ({
        loading: false,
        error: undefined,

        methods,

        identity: undefined,

        password1: '',
        password2: '',

        saving: false,
        saveError: undefined,

        success: false,
    }),

    mounted() {
        this.refresh();
    },

    computed: {
        isValid() {
            const { notify_method: method, notify_config: config } = this.identity;
            const { password1, password2 } = this;

            if (password1.length && (password1.length < 8 || password1 !== password2)) {
                return false;
            }

            const fn = validators[method];
            return !fn || fn(config);
        },
    },

    watch: {
        'identity.notify_method'() {
            if (!this.loading && !this.saving) {
                this.identity.notify_config = this.identity.notify_method ? {} : undefined;
            }
        },
    },

    methods: {
        async refresh() {
            this.loading = true;
            this.error = undefined;
            this.saveError = undefined;

            try {
                this.identity = await api.identity.get();
            } catch (err) {
                this.error = err.message;
            } finally {
                this.$nextTick(() => { this.loading = false; });
            }
        },

        async save() {
            this.saving = true;
            this.saveError = undefined;

            try {
                const newIdentity = { ...(this.identity) };

                if (this.password1.length) {
                    newIdentity.password = this.password1;
                }

                this.identity = await api.identity.put(newIdentity);

                this.password1 = this.password2 = '';
            } catch (err) {
                this.saveError = err.response?.status === 422
                    ? `Settings rejected by server: ${err.response.data.error}`
                    : err.message;
            } finally {
                this.$nextTick(() => { this.saving = false; });
            }
        },
    },
};
</script>
