<template>
    <div class="container">
        <nav class="navbar navbar-expand navbar-dark bg-dark">
            <span class="navbar-brand">WatchlistBot 2</span>
            <ul class="navbar-nav mr-auto">
                <template v-if="isLoggedIn">
                    <li class="nav-item" :class="{ active: $route.name === 'changes' }">
                        <router-link class="nav-link" to="/change">Changes ({{ feedstate.changes.length }})</router-link>
                    </li>
                    <li class="nav-item" :class="{ active: $route.name === 'watchlist' }">
                        <router-link class="nav-link" to="/watchlist">Watchlist</router-link>
                    </li>
                    <li class="nav-item" :class="{ active: $route.name === 'settings' }">
                        <router-link class="nav-link" to="/settings">Settings</router-link>
                    </li>
                </template>
            </ul>
            <div class="navbar-text">
                Change feed: <span :class="stateClass">{{ stateLabel }}</span>
                <button type="button" class="ml-2 btn btn-sm btn-secondary" @click.prevent="logout()" v-if="isLoggedIn">Log out</button>
            </div>
        </nav>
        <div class="p-3">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import api from './api';
import hub from './changefeed';

const stateLabels = {
    connected: 'Connected',
    connecting: 'Connecting',
    inactive: 'Disconnected',
};

const stateClasses = {
    connected: 'text-success',
    connecting: 'text-warning',
    inactive: 'text-danger',
};

export default {
    data: () => ({
        feedstate: hub.state,
    }),

    computed: {
        stateLabel() {
            return stateLabels[hub.state.feedState];
        },

        stateClass() {
            return stateClasses[hub.state.feedState];
        },

        isLoggedIn() {
            return hub.state.feedState !== 'inactive';
        },
    },

    methods: {
        logout() {
            api.logout();
        },
    },
};
</script>
