import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import mitt from 'mitt';

const axios = require('axios');
const EventSource = require('eventsource');

const tokenKey = 'wlbtoken';
const storage = global.localStorage;

function getToken() {
    return storage.getItem(tokenKey);
}

function setToken(token) {
    return storage.setItem(tokenKey, token);
}

function clearToken() {
    return storage.removeItem(tokenKey);
}

const bus = mitt();

const baseURL = process.env.NODE_ENV === 'production' ? '/api/v1/' : 'http://127.0.0.1:50081/api/v1/';

const client = axios.create({ baseURL });

client.interceptors.request.use(config => {
    const token = getToken();
    if (!token) { return config; }

    return {
        ...config,
        headers: {
            ...config.headers,
            authorization: `Bearer ${token}`,
        },
    };
});

function logout() {
    clearToken();
    bus.emit('logout');
}

client.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 401) {
            const token = getToken();

            if (token) {
                logout();
            }
        }

        return Promise.reject(error);
    }
);

function uriTemplate(uri) {
    const parts = uri.split('/');

    const placeholders = filter(
        map(parts, (part, idx) => part === '$' ? idx : undefined),
        i => i !== undefined
    );

    const template = placeholders.length

        ? args => {
            const copy = [...parts];

            forEach(placeholders, (copyIdx, argsIdx) => {
                const v = args[argsIdx];
                if (v === undefined) {
                    throw new Error(`Missing argument ${argsIdx + 1}`);
                }

                copy[copyIdx] = encodeURIComponent(v);
            });

            return copy.join('/');
        }

        // Fast case for no placeholders
        : () => uri;

    template.templateLength = placeholders.length;

    return template;
}

function basicGet(uri) {
    const template = uriTemplate(uri);

    return async (...args) => (await client.get(template(args))).data;
}

function basicPut(uriT) {
    const template = uriTemplate(uriT);

    return async (...args) => {
        const uri = template(args);

        const obj = args[template.templateLength];
        if (!obj) {
            throw new Error('Missing object to put');
        }

        return (await client.put(uri, obj)).data;
    };
}

export default {
    bus,

    get isAuthenticated() {
        return getToken() !== null;
    },

    async authenticate(username, password) {
        const { data } = await client.post('authenticate', { username, password });

        setToken(data.token);

        bus.emit('login');
    },

    logout,

    getSiteMatrix: basicGet('sitematrix'),

    identity: {
        get: basicGet('identity'),
        put: basicPut('identity'),
    },

    watchlist: {
        article: {
            getAll: basicGet('watchlist/article'),
            put: basicPut('watchlist/article/$/$'),
        },
        editor: {
            getAll: basicGet('watchlist/editor'),
            put: basicPut('watchlist/editor/$/$'),
        },
        network: {
            getAll: basicGet('watchlist/network'),
            put: basicPut('watchlist/network/$/$'),
        },
    },

    ignore: {
        getAll: basicGet('ignore'),
        put: basicPut('ignore/$/$'),
    },

    change: {
        getAll: basicGet('change'),
        feed: (fromId) => {
            const token = getToken();

            if (token) {
                return new EventSource(`${baseURL}change/feed?keepalive=20`, {
                    headers: {
                        authorization: `Bearer ${token}`,
                        'Last-Event-ID': `${fromId}`,
                    },
                });
            }
        },
    },

    async putObject(obj) {
        return (await client.put(obj.$uri, obj)).data;
    },

    async deleteObject(obj) {
        return (await client.delete(obj.$uri)).data;
    },
};
