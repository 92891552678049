<template>
    <teleport to="body">
        <div class="modal-backdrop fade show" v-if="modalOpen"></div>
        <div class="modal fade" :class="{ show: modalOpen, 'wlb-modal-show': modalOpen }" @click.self="modalOpen = false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Dismiss visible</h5>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to dismiss all visible changes?
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" @click.prevent="modalOpen = false">No</button>
                        <button class="btn btn-danger" @click.prevent="modalOpen = false; deleteVisible();">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>

    <div class="row">
        <button class="btn btn-primary col-12" :class="{ invisible: !newChanges }" @click.prevent="updateChanges()">Show new changes ({{ changesSource.length - changes.length }})</button>
        <form class="row col-12 mb-3">
            <div class="col-6">
                <template v-if="categories.length">
                    <div class="mb-2">Filter by category:</div>
                    <div class="btn-group btn-group-toggle mr-2">
                        <label class="btn btn-secondary" :class="{ active: filterCategory === undefined }">
                            <input type="radio" name="category-filter" :value="undefined" v-model="filterCategory"> All ({{ changes.length}})
                        </label>
                    </div>
                    <div class="btn-group btn-group-toggle">
                        <label v-for="cat in categories" :key="cat.cat" class="btn btn-secondary" :class="{ active: cat.cat === filterCategory }">
                            <input type="radio" name="category-filter" :value="cat.cat" v-model="filterCategory"> {{ cat.cat }} ({{ cat.count }})
                        </label>
                    </div>
                </template>
            </div>
            <div class="col-6 text-right">
                <div class="mb-2">&nbsp;</div>
                <button class="btn btn-danger" :disabled="filteredChanges.length === 0" @click.prevent="modalOpen = true">Dismiss visible ({{ filteredChanges.length }})</button>
            </div>
        </form>
        <div v-for="c in filteredChanges" :key="c.id" class="card col-6 change" :class="{ 'bg-secondary': oldChange !== undefined && c.id > oldChange }">
            <div class="card-body">
                <div class="float-right change-tools">
                    <button class="btn btn-danger" @click.prevent="deleteChange(c)">Dismiss</button>
                </div>
                <p>
                    {{ (new Date(c.change.meta.dt)).toLocaleString() }}
                    <span class="mr-1 badge badge-success" v-if="c.notified">Notified</span>
                    <span class="mr-1 badge badge-primary" v-for="category in c.categories" :key="category">{{ category }}</span>
                </p>
                <p><WikiLink :wiki-base-url="c.change.server_url" :page="`User:${c.change.user}`">{{ c.change.user }}</WikiLink> {{ c.change.type }} on <WikiLink :wiki-base-url="c.change.server_url" :page="c.change.title">{{ c.change.title }}</WikiLink></p>
                <p v-if="c.change.type === 'log'">Log ({{ c.change.log_action }}): {{ c.change.log_action_comment }}</p>
                <p v-if="c.change.comment">Edit summary: {{ c.change.comment }}</p>
                <p v-if="c.change.revision"><a :href="`${c.change.server_url}${c.change.server_script_path}/index.php?oldid=${c.change.revision.old}&diff=${c.change.revision.new}`" target="_blank">View diff</a></p>
                <p>This change was reported because:</p>
                <ul>
                    <li v-for="reason in c.reasons" :key="reason">{{ reason }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import throttle from 'lodash/throttle';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
import max from 'lodash/max';
import some from 'lodash/some';

import changefeed from '../changefeed';
import api from '../api';

import WikiLink from '../components/WikiLink';

export default {
    components: {
        WikiLink,
    },

    data: () => ({
        changes: [],
        changesSource: changefeed.state.changes,

        oldChange: undefined,

        categories: [],
        filterCategory: undefined,

        newChanges: false,

        modalOpen: false,
    }),

    computed: {
        filteredChanges() {
            const { filterCategory, changes } = this;

            return filterCategory ? filter(changes, c => includes(c.categories, filterCategory)) : changes;
        },
    },

    mounted() {
        this.newChanges = false;
        this.updateChanges();
    },

    watch: {
        changesSource: {
            handler: throttle(
                function () {
                    if (this.changes.length) {
                        this.newChanges = this.changesSource.length > this.changes.length;
                    } else {
                        this.updateChanges();
                    }
                },
                500,
                { leading: false, trailing: true }
            ),
            deep: true,
        },

        changes: {
            handler() {
                this.categories = orderBy(
                    Object.values(
                        reduce(
                            this.changes,
                            (o, c) => {
                                forEach(c.categories, cat => {
                                    if (!o[cat]) {
                                        o[cat] = { cat, count: 1 };
                                    } else {
                                        o[cat].count += 1;
                                    }
                                });

                                return o;
                            },
                            {}
                        )
                    )
                );

                if (this.filterCategory !== undefined && !some(this.categories, { cat: this.filterCategory })) {
                    this.filterCategory = undefined;
                }
            },
            deep: true,
        },
    },

    methods: {
        updateChanges() {
            this.oldChange = max(map(this.changes, 'id'));

            this.changes = [...orderBy(this.changesSource, ['id'], ['desc'])];
            this.newChanges = false;
        },

        async deleteChange(c) {
            try {
                await api.deleteObject(c);

                remove(this.changes, { id: c.id });
                remove(this.changesSource, { id: c.id });
            } catch (err) {
                console.log(err);
            }
        },

        async deleteVisible() {
            try {
                const toDelete = [...this.filteredChanges];
                const idLookup = fromPairs(
                    map(toDelete, ({ id }) => [ id, true ])
                );

                await Promise.all(
                    map(toDelete, api.deleteObject)
                );

                remove(this.changesSource, ({ id }) => idLookup[id]);

                this.updateChanges();
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style>
.change-tools {
    visibility: hidden;
}

.change:hover .change-tools {
    visibility: visible;
}

.wlb-modal-show {
    display: block;
    padding-right: 17px;
}
</style>
