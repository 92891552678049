import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';

import Login from './views/Login.vue';
import Watchlist from './views/Watchlist.vue';
import Changes from './views/Changes.vue';
import Settings from './views/Settings.vue';

import some from 'lodash/some';

import api from './api';
import changefeed from './changefeed';

const { Notification } = global;
if (Notification) {
    (async () => {
        if (Notification.permission === 'granted') {
            return true;
        }

        return new Promise(resolve => {
            Notification.requestPermission(result => {
                resolve(result === 'granted');
            });
        });
    })()
    .then(r => {
        if (r) {
            changefeed.on('change', c => {
                if (c.notified) {
                    new Notification('WatchlistBot 2 - New change', {
                        body: `${c.change.user} ${c.change.type} on ${c.change.title}`
                    });
                }
            });
        }
    });
}

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/watchlist',
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/watchlist',
            name: 'watchlist',
            component: Watchlist,
            meta: { authed: true },
        },
        {
            path: '/change',
            name: 'changes',
            component: Changes,
            meta: { authed: true },
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: { authed: true },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (some(to.matched, i => i.meta?.authed) && !api.isAuthenticated) {
        next({ name: 'login' });
    } else {
        next();
    }
});

api.bus.on('login', () => {
    router.push({ name: 'watchlist' });
});

api.bus.on('logout', () => {
    router.push({ name: 'login' });
});

const app = createApp(App);
app.use(router);
app.mount('#app');
