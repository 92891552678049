<template>
    <span>
        <a v-if="pageUrl !== undefined" :href="pageUrl" target="_blank"><slot></slot></a>
        <template v-else><slot></slot></template>
    </span>
</template>

<script>
//import { Title } from 'mediawiki-title';
//import { getSiteInfo } from '../siteinfo';

function runUpdate() {
    this.update();
}

export default {
    props: ['wikiBaseUrl', 'page', 'defaultNamespace'],

    data: () => ({ pageUrl: undefined }),

    mounted() {
        this.update();
    },

    watch: {
        wikiBaseUrl: runUpdate,
        page: runUpdate,
        defaultNamespace: runUpdate,
    },

    methods: {
        update() {
            if (this.defaultNamespace !== undefined && this.defaultNamespace !== 0) {
                // Default is not article space, so we need this wiki's siteinfo in
                // order to figure out the localized namespace prefix.
                this.pageUrl = undefined;

                // TODO siteinfo can't be loaded because of CORS.
            } else {
                // Default is article space, so we can cheat.
                this.pageUrl = `${this.wikiBaseUrl}/w/index.php?title=${encodeURIComponent(this.page)}`;
            }
        },
    },
};
</script>
