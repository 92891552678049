import axios from 'axios';
import memoize from 'memoizee';
import ms from 'ms';

import api from './api';

export const getSiteMatrix = memoize(
    api.getSiteMatrix,
    { promise: true, primitive: true, maxAge: ms('1h') }
);

export const getSiteInfo = memoize(
    async url => (
        (await axios.get(`${url}/w/api.php?action=query&meta=siteinfo&format=json&siprop=general%7Cnamespaces%7Cnamespacealiases%7Cspecialpagealiases`)).data
    ),
    { promise: true, primitive: true, maxAge: ms('1h') }
);
