<template>
    <button type="button" class="btn" :class="{ 'btn-success': value, 'btn-secondary': !value }" @click.prevent="value = !value">{{ value ? 'Yes' : 'No' }}</button>
</template>

<script>
export default {
    props: ['modelValue', 'projectLang'],
    emits: ['update:modelValue'],

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            },
        },
    },
};
</script>
