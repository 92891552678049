<template>
    <select v-model="value">
        <option v-for="project in projects" :key="project.dbname" :value="project.dbname">{{ project.sitename }} ({{ project.dbname }})</option>
    </select>
</template>

<script>
import find from 'lodash/find';
import filter from 'lodash/filter';

import { getSiteMatrix } from '../siteinfo';

export default {
    props: ['modelValue', 'projectLang'],
    emits: ['update:modelValue'],

    data: () => ({
        projects: [],
    }),

    watch: {
        projectLang() {
            this.refresh();
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async refresh() {
            this.projects = [];

            const matrix = await getSiteMatrix();

            this.projects = filter(
                (
                    this.projectLang === 'specials'
                    ? matrix.specials
                    : find(matrix, { code: this.projectLang })?.site || []
                ),
                i => !i.closed && !i.private
            );

            if (this.projects.length && !find(this.projects, { dbname: this.modelValue })) {
                this.$emit('update:modelValue', this.projects[0].dbname);
            }
        },
    },

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            },
        },
    },
};
</script>
