import { reactive } from 'vue';
import mitt from 'mitt';
import some from 'lodash/some';

import api from './api';

let source;

export const state = reactive({
    changes: [],
    feedState: 'inactive',
});

const bus = Object.assign(mitt(), { state });

export default bus;

function newChange(data, notify) {
    const change = JSON.parse(data);

    if (!some(state.changes, { id: change.id })) {
        state.changes.push(change);
    }

    if (notify) {
        bus.emit('change', change);
    }
}

function updateState() {
    if (api.isAuthenticated) {
        if (!source) {
            source = api.change.feed(1);

            source.addEventListener('open', () => {
                state.feedState = 'connected';
            });

            source.addEventListener('error', err => {
                state.feedState = 'connecting';

                if (err.status === 401) {
                    api.logout();
                }
            });

            source.addEventListener('change', ({ data }) => {
                newChange(data, true);
            });

            source.addEventListener('history', ({ data }) => {
                newChange(data, false);
            });

            state.feedState = 'connecting';
        }
    } else {
        if (source) {
            source.close();
            source = undefined;

            state.changes = [];
            state.feedState = 'inactive';
        }
    }
}

updateState();

api.bus.on('logout', updateState);
api.bus.on('login', updateState);
